<!-- eslint-disable vue/no-v-html -->
<template>
  <span>
    <v-snackbar
      v-for="(toast, i) in toasters"
      :key="i"
      v-model="toast.showSnackbar"
      :style="{'margin-bottom':calcMargin(i)}"
      :color="toast.color || 'grey'"
      :timeout="toast.timer || 5000"
      multi-line
    >
      <v-row no-gutters>
        <v-col
          v-if="toast.icon"
          cols="1"
          align="center"
          align-self="center"
        >
          <v-icon class="pr-3">{{ toast.icon }}</v-icon>
        </v-col>
        <v-col :cols="toast.icon ? 10 : 11">
          <strong v-if="toast.title">{{ toast.title }}</strong>
          <div
            class="text-caption"
            v-html="toast.text"
          />
        </v-col>
        <v-col
          cols="1"
          align="center"
          align-self="center"
        >
          <v-btn
            icon="mdi mdi-close"
            variant="text"
            @click="removeToaster(toast.id)"
          />
        </v-col>
      </v-row>
    </v-snackbar>
  </span>
</template>

<script lang="ts">
import {baseUseNotifyStore} from '../store/notify'

export default {

    name: "VToast",
    setup() {
        const notifyStore = baseUseNotifyStore()
        return {
            notifyStore,
        }
    },
    data() {
        return {
            showSnackbar: true,
        }
    },
    computed : {
        toasters () {
            return this.notifyStore.toasters.map(toast => ({...toast, showSnackbar : true}))
        },
    },
    created () {
        // this.notifyStore.$subscribe((mutation) => {
        //     // if (this.message) this.showSnackbar = true
        // })
    },
    methods : {
        calcMargin(i) {
            return (i*100) + 10 + 'px'
        },
        removeToaster (index) {
            this.notifyStore.removeToaster(index)
        },
    },
}
</script>
~/store
